// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-browser-support-tsx": () => import("./../../../src/pages/browser-support.tsx" /* webpackChunkName: "component---src-pages-browser-support-tsx" */),
  "component---src-pages-considerations-tsx": () => import("./../../../src/pages/considerations.tsx" /* webpackChunkName: "component---src-pages-considerations-tsx" */),
  "component---src-pages-examples-around-the-web-tsx": () => import("./../../../src/pages/examples/around-the-web.tsx" /* webpackChunkName: "component---src-pages-examples-around-the-web-tsx" */),
  "component---src-pages-examples-dotted-loader-tsx": () => import("./../../../src/pages/examples/dotted-loader.tsx" /* webpackChunkName: "component---src-pages-examples-dotted-loader-tsx" */),
  "component---src-pages-examples-random-blob-tsx": () => import("./../../../src/pages/examples/random-blob.tsx" /* webpackChunkName: "component---src-pages-examples-random-blob-tsx" */),
  "component---src-pages-examples-rotating-loader-tsx": () => import("./../../../src/pages/examples/rotating-loader.tsx" /* webpackChunkName: "component---src-pages-examples-rotating-loader-tsx" */),
  "component---src-pages-examples-tsx": () => import("./../../../src/pages/examples.tsx" /* webpackChunkName: "component---src-pages-examples-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-properties-classname-style-tsx": () => import("./../../../src/pages/properties/classname-style.tsx" /* webpackChunkName: "component---src-pages-properties-classname-style-tsx" */),
  "component---src-pages-properties-composite-tsx": () => import("./../../../src/pages/properties/composite.tsx" /* webpackChunkName: "component---src-pages-properties-composite-tsx" */),
  "component---src-pages-properties-id-tsx": () => import("./../../../src/pages/properties/id.tsx" /* webpackChunkName: "component---src-pages-properties-id-tsx" */),
  "component---src-pages-properties-intensity-tsx": () => import("./../../../src/pages/properties/intensity.tsx" /* webpackChunkName: "component---src-pages-properties-intensity-tsx" */),
  "component---src-pages-properties-tsx": () => import("./../../../src/pages/properties.tsx" /* webpackChunkName: "component---src-pages-properties-tsx" */),
  "component---src-pages-tutorial-tsx": () => import("./../../../src/pages/tutorial.tsx" /* webpackChunkName: "component---src-pages-tutorial-tsx" */)
}

